* {
  font-family: 'Nunito';
}

.hr {
  flex-shrink: 0;
  background-color: initial;
  height: 0.0625rem;
  margin: 2rem auto;
  border: 0 solid rgba(0, 0, 0, .12);
  border-bottom: none;
  opacity: .25;
  background-image: linear-gradient(90deg, rgba(52, 71, 103, 0), rgba(52, 71, 103, .5), rgba(52, 71, 103, 0)) !important;
  width: 50%;
}