.navbar {
  width: 100%;
  background-color: white;
  padding: 10px 35px !important;
  /* position: fixed !important; */
  top: 0px;
  box-shadow: 0px 0px 5px 0px #e5e5e5;
  z-index: 9;
}

.navbar-logo {
  width: 160px;
  height: 80px;
  /* border-radius: 50%; */
  border: none;
}

.navbar .logo-box {
  display: flex;
  align-items: self-end;
}

.navbar .logo-text {
  margin-bottom: 5px;
  margin-left: 8px;
  font-size: 25px;
  font-weight: 400;
  font-family: "Pacifico";
  text-transform: lowercase;
  letter-spacing: 0px;
  color: #dd5421;
  display: none;
}

span.navbar-link {
  margin: 0px 20px;
  cursor: pointer;
}

.navbar-link a {
  color: black;
  text-decoration: none;
}

.navbar-link a:hover {
  color: black;
}

@media screen and (max-width: 800px) {
  .navbar {
    flex-direction: column;
    align-items: start !important;
  }

  .navbar-items {
    display: flex;
    flex-direction: column;
  }

  span.navbar-link {
    margin: 0px;
    padding: 10px 0px;
  }
}

.intro-section {
  width: 100%;
  background-color: #f3f3f3;
  height: 600px;
  padding: 70px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 100px; */
}

.intro-box {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: center;
  letter-spacing: 0.023rem;
}

.intro-box .bold-text {
  font-weight: 700;
  color: #dd5421;
}

@media screen and (max-width: 800px) {
  .intro-section {
    height: auto;
    padding: 70px 10px;
  }

  .intro-box {
    width: 90%;
  }
}

.services-section {
  width: 100%;
  background-color: white;
  height: auto;
  padding: 70px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.services-inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.services-card {
  width: 300px;
  background-color: #fcfcfc;
  padding: 10px;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 12px 1px #efefef;
  border: 0px solid #efefef;
  margin: 15px;
  height: 430px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.services-card:hover {
  box-shadow: 4px 3px 18px 0px #e9e9e9;
}

.services-card img {
  width: 100%;
  height: 50%;
}

span.services-title {
  font-weight: 700;
  letter-spacing: 0.01111rem;
  color: #1e2123;
  display: block;
  font-size: 15px;
}

span.service-body {
  margin-top: 5px;
  display: block;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0.3px;
}

@media screen and (max-width: 800px) {
  .services-section {
    padding: 70px 10px;
  }

  .services-inner-box {
    justify-content: center;
  }
}

.team-section {
  width: 100%;
  background-color: #f3f3f3;
  height: auto;
  padding: 70px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.team-details {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.team-box img {
  width: 500px;
  height: 500px;
  border-radius: 2rem;
  filter: grayscale(40%) contrast(130%) brightness(110%)
    drop-shadow(2px 4px 6px rgb(129, 129, 129));
}

.team-description {
  padding: 20px;
  display: flex;
}

.team-description p {
  text-align: justify;
}

.team-description i {
  padding: 0px 5px;
  font-size: 20px;
  color: #9c27b0;
  cursor: pointer;
}

.team-description i:hover {
  font-size: 25px;
  /* transition: font-size 1s ease-in; */
}

.team-content {
  padding: 20px 40px;
}

.team-icons {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

@media screen and (max-width: 800px) {
  .team-section {
    padding: 70px 10px;
  }

  .team-description {
    flex-direction: column;
  }

  .team-box img {
    width: 100%;
    height: 100%;
  }

  .team-content {
    padding: 20px 0px;
  }
}

.gallery-section {
  width: 100%;
  background-color: white;
  height: auto;
  padding: 70px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.gallery-inner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.gallery-card {
  width: 280px;
  height: 365px;
  margin: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 6px solid black;
  border-radius: 2px;
}

.gallery-card:hover {
  transform: scale(1.1);
  border: 10px solid black;
}

.gallery-img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .gallery-section {
    padding: 70px 10px;
  }
}

.contact-section {
  width: 100%;
  background-color: #f3f3f3;
  height: 350px;
  padding: 70px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.contact-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-sub-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: brown;
}

.contact-inner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.contact-card {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-box i {
  margin-right: 15px;
  font-size: 25px;
  color: brown;
  text-transform: uppercase;
}

.contact-box .fa-coffee {
  font-size: 16px;
  margin-right: 0px;
}

span.contact-text {
  letter-spacing: 0.0111rem;
  font-size: 14px;
  font-weight: 600;
  color: #747474;
  text-transform: uppercase;
}

.copyright {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  font-weight: 500;
  font-size: 13px;
  color: #3a3939;
}

.fa-copyright {
  margin-right: 3px;
}

@media screen and (max-width: 800px) {
  .contact-section {
    height: 380px;
  }

  .contact-inner-box {
    flex-direction: column;
  }

  .contact-card {
    width: 100%;
    justify-content: start;
    padding: 8px 0px;
  }

  .copyright {
    bottom: 10px;
  }
}

/* Commmon */
h1 {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
}
